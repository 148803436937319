import LazyLoad from "vanilla-lazyload";

$(document).ready(function () {

    $(document).on('scroll', function () {
        if ($(document).scrollTop() > 0) {
            $("header").addClass("scrollingdown");
            $('.logo.logo-normal img').attr('src', '/img/logo/logo-barberousse-small.svg')
            $('.logo.logo-maison img').attr('src', '/img/logo/logo-LA-maison-barberousse-couleur.svg')
            $('.alsace').attr('src', "/img/icones/alsace-black.png")
        } else {
            $("header").removeClass("scrollingdown");
            $('.logo.logo-normal img').attr('src', '/img/logo/logo-barberousse.png')
            $('.logo.logo-maison img').attr('src', '/img/logo/logo-LA-maison-barberousse-fond-blanc.png')
            $('.alsace').attr('src', "/img/icones/alsace-blanc.png")
        }
    })

    /*
    MENU
     */
    $('.toggleMenu').on('click', function (e) {
        e.preventDefault();
        $(this).find('span').fadeToggle()
        $('header').toggleClass('nav-open')
        $('.logo').toggleClass('opacity-0')

        /* Change picto */
        let picto = $('#menu svg use').attr('href')
        let newPicto = (picto === '/img/icones/menu.svg#menu') ? '/img/icones/arrow.svg#arrow' : '/img/icones/menu.svg#menu';
        $('#menu svg use').attr('href', newPicto);

        if ($('header').hasClass('scrollingdown')) {
            $('header').toggleClass('scrollingdown')
        }

        /*Ouvre le menu*/
        $('#nav').toggleClass('-translate-x-full');
        $('body').toggleClass('overflow-hidden')
    })

    /* Change img */
    $('nav ul li').on('mouseenter', function () {
        let data = $(this).data('menu');
        $('#nav .nav-img > img').fadeOut()
        $('#nav .nav-img > img[data-img="' + data + '"]').fadeIn()
    })

    /* FORMS */
    const formFields = $('.form-item');

    formFields.each(function () {
        let field = $(this);
        let input = field.find('> *').not('label');

        function checkInput() {
            let inputLength = input.val().length;

            if (inputLength > 0) {
                input.addClass('active');
            } else {
                input.removeClass('active');
            }
        }

        input.change(function () {
            checkInput();
        });

        checkInput();
    });

    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
        let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })
    $('#contact_form_terrain ~ span').on('click', function () {
        $(this).parent().next('div').slideToggle()
    })
    $('#contact_form_terrain').change(function () {
        $(this).parent().next('div').slideToggle()
    })
    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="' + title + '"]').removeClass('hidden');
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-' + id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').on('click', function () {
        $('.popup').fadeOut();
    })
    $('.popup').on('click', function (e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('.popup').fadeOut();
        }
    });

    var lazyLoadInstance = new LazyLoad({});
    window.addEventListener("LazyLoad::Initialized", function (event) {
        window.lazyLoadInstance = event.detail.instance;
    }, false);

});

/*
SLIDERS
 */
import Swiper, {Navigation, Pagination, EffectFade} from 'swiper';
Swiper.use([Navigation, Pagination, EffectFade]);


const swiperPartenaires = new Swiper('#slider-partenaires', {
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    spaceBetween: 50,
    navigation: {
        nextEl: '.swiper-partenaires_next',
        prevEl: '.swiper-partenaires_prev',
    },
    breakpoints: {
        480: {
            slidesPerView: 2,
            centeredSlides: false
        },
        680: {
            slidesPerView: 3,
            centeredSlides: true
        },
        1024: {
            slidesPerView: 4,
            centeredSlides: false
        },
        1400: {
            slidesPerView: 5,
            centeredSlides: true
        }
    },
})

const swiperProgrammes = new Swiper('#slider-programmes', {
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    effect:'fade',
    pagination: {
        el: ".swiper-materiaux_pagination",
        type: "progressbar",
    },
    navigation: {
        nextEl: '.swiper-programmes_next',
        prevEl: '.swiper-programmes_prev',
    },
})

let nbSlideRealisation = $('#slider-realisations-biens .swiper-slide').length
const swiperRealisationBiens = new Swiper('#slider-realisations-biens', {
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    spaceBetween:40,
    slidesPerView:1,
    pagination: {
        el: ".swiper-realisations_pagination",
        type: "progressbar",
    },
    navigation: {
        nextEl: '.swiper-realisations_next',
        prevEl: '.swiper-realisations_prev',
    },
    breakpoints :{
        640:{
            slidesPerView:1,
            spaceBetween:60,
        },
    },
    on: {
        init: function (sw) {
            let total = document.querySelector('.slide-count-total');
            total.innerHTML = nbSlideRealisation
        },
        slideChange: function (sw) {
            let index = $('.slide-count-index')
            index.text(sw.realIndex + 1);
        },
        resize: function (sw) {
            let total = document.querySelector('.slide-count-total');
            total.innerHTML = nbSlideRealisation
        }
    }
})
